.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.data-table-wrapper {
  width: 100%
}

table.dataTable {
  border-collapse: separate;
  empty-cells: hide;
}

.amplify-tabs {
  display: none !important;
  ;
}



[data-amplify-authenticator] {

  --amplify-components-image-height: 50px;
  --amplify-components-fieldcontrol-border-color: #ccc;
  --amplify-components-textfield-border-color: #ccc;
  --amplify-components-button-border-color: #ccc;
  --amplify-components-authenticator-router-border-color: #f1f1f1;
  --amplify-components-authenticator-modal-background-color: "white"

}

.navbar-dark .navbar-nav .nav-link {
  color: white
}

.modals {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-contents {
  width: 50%;
  background-color: white;
  padding: 20px;
  position: relative;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.modal-contents table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 5px;
}

.modal-contents table td {
  padding: 5px;
}

.modal-contents table td:first-child {
  font-weight: bold;
}

.reply {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  border: 1px grey solid;
  padding: 5px;
  border-radius: 5px;
  justify-content: space-between;
}

.reply-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.reply-username {
  font-size: 14px;
  font-weight: bold;
}

.reply-time {
  font-size: 12px;
  color: #999;
  margin-top: 8px;
  text-align: right;
}

.reply-text {
  font-size: 16px;
  margin-bottom: 8px;
  width: 100%;
  word-wrap: break-word;

}

.reply-close-button {
  font-size: 12px;
  color: #999;
  border: none;
  background-color: transparent;
  cursor: pointer;
}



.Viewer {
  min-height: var(--full-vh, 100vh);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #121313;
  overflow-x: hidden;
}

.Viewer__modelViewer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.Viewer__modelViewer__wrap {
  position: relative;
  width: calc(100vw);
  flex-grow: 1;
  min-height: 300px;
}

.Viewer__header {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  transition: all 1s ease-in-out;
  transition-delay: 500ms;
}

.Viewer__header__alpha {
  margin-left: 2px;
}

.Viewer__header__loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #1a1a1a;
  gap: 12px;
}

.Viewer__header__loaded {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 78px;
  background-color: #12131350;
}

.Viewer__header__loader {
  transition: all 500ms ease-out;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Viewer__header__message {
  margin-top: 12px;
}

@keyframes splash {}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
  -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
  -webkit-animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
  -webkit-animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
  -webkit-animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
  -webkit-animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}

.dt-center {
  vertical-align: middle !important;
}